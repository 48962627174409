<template id="coachcard">
  <b-card class="m-1">
    <b-row align-v="center" v-if="auth.isUserEnable('opta_coach')">
      <b-col cols="1" class="p-0">
        <b-row>
          <b-col
            v-for="skill in standard_skills"
            cols="12"
            class="p0 pb-1"
            :key="'st_' + skill.id"
          >
            <div
              class="round_stats"
              :style="
                'background:' +
                getBgStat(skill.id, getValueStat(skill.id)) +
                ';' +
                (getValueStat(skill.id).length < 4 ? 'font-size: .8rem;' : '')
              "
              :title="skill.label"
            >
              {{ getValueStat(skill.id) }}
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6" class="p-0" align-self="center">
        <polar-coach
          :index="1"
          :coach_id="coach.id"
          :coach_avatar="
            coach.allenatore
              ? coach.allenatore.avatar_url
              : '/assets/images/agente.png'
          "
          :skills_fasi="skills_fasi"
          :coach_data="coach_data"
          :width="230"
          :height="230"
        />
      </b-col>
      <b-col cols="5">
        <b-row class="mb-5">
          <b-col cols="12" style="font-size: 0.8rem; font-weight: bold">
            <div
              :title="
                coach.allenatore
                  ? coach.allenatore.nome + ' ' + coach.allenatore.cognome
                  : (coach.initial ? coach.initial : '') + ' ' + coach.name
              "
              style="
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{
                coach.allenatore
                  ? coach.allenatore.nome + " " + coach.allenatore.cognome
                  : (coach.initial ? coach.initial : "") + " " + coach.name
              }}
            </div>
          </b-col>
          <b-col cols="12" style="font-size: 0.8rem; min-height: 18px">{{
            coach.last_stat ? coach.last_stat.team.name : ""
          }}</b-col>
          <b-col cols="12">
            <flag
              :country="
                coach.allenatore && coach.allenatore.paese_nascita
                  ? coach.allenatore.paese_nascita.sigla
                  : ''
              "
              :size="16"
              class="mr-2"
              title="Country of birth"
            ></flag>
            <flag
              :country="
                coach.allenatore && coach.allenatore.passaporto
                  ? coach.allenatore.passaporto.sigla
                  : ''
              "
              :size="16"
              class="mr-2"
              title="Passport"
            ></flag
          ></b-col>
        </b-row>
        <b-row align-v="end" v-if="auth.isUserEnable('opta_coach')">
          <b-col
            cols="5"
            class="p-0"
            @click="getRankIndexMacro"
            style="cursor: pointer"
          >
            <Progress
              :class="
                coach.optaIndex && coach.optaIndex.games < 3 ? 'macro_red' : ''
              "
              title="macro"
              :strokeColor="
                coach.optaIndex
                  ? bg_opta[coach.optaIndex.macro_index_color_rank]
                  : '#FFFFFF'
              "
              :value="
                coach.optaIndex
                  ? Math.round(coach.optaIndex.macro_index * 100) / 100
                  : 0
              "
              :radius="25"
              :strokeWidth="4"
              :transitionDuration="0"
            >
            </Progress>
          </b-col>
          <b-col
            cols="7"
            class="p-0"
            @click="getRankIndexMicro"
            style="cursor: pointer"
          >
            <Progress
              title="micro"
              :class="
                coach.optaIndex && coach.optaIndex.games < 3
                  ? 'micro2_red'
                  : 'micro2'
              "
              :strokeColor="
                coach.optaIndex
                  ? bg_opta[coach.optaIndex.micro_index_color_rank]
                  : '#FFFFFF'
              "
              :value="
                coach.optaIndex
                  ? Math.round(coach.optaIndex.micro_index * 100) / 100
                  : 0
              "
              :radius="45"
              :strokeWidth="7"
              :transitionDuration="0"
            >
            </Progress>
          </b-col>
        </b-row>

        <b-modal
          :ref="'coachRankIndexMacroModal' + coach.id"
          :title="'Coaches index ranking: ' + title_index_rank"
          ok-only
          ok-title="Close"
        >
          <b-row
            align-v="center"
            v-for="(item, index) in coaches_index_rank"
            :key="index"
            class="p-1"
          >
            <b-col
              cols="1"
              class="text-center"
              :style="coach.id == item.coach.id ? 'color:#FF0000' : ''"
            >
              {{ index + 1 }}
            </b-col>
            <b-col cols="2" class="text-center">
              <b-img
                :src="
                  item.coach && item.coach.allenatore
                    ? item.coach.allenatore.avatar_url
                    : '/assets/images/agente.png'
                "
                height="30"
              ></b-img
            ></b-col>
            <b-col :style="coach.id == item.coach.id ? 'color:#FF0000' : ''">
              <router-link
                :to="{
                  name: 'ScoutingViewCoach',
                  params: { id: item.coach.id },
                }"
                target="_blank"
                :style="
                  'cursor: pointer;' +
                  (coach.id == item.coach.id ? 'color:#FF0000' : '')
                "
                >{{
                  item.coach.allenatore
                    ? item.coach.allenatore.cognome
                    : item.coach.name
                }}
              </router-link>
            </b-col>
            <b-col cols="3">
              <div
                :style="
                  'color:#000000;background:' +
                  bg_opta[item.macro_index_color_rank]
                "
                class="text-center"
              >
                <strong>{{ item.macro_index }}</strong>
              </div></b-col
            >
          </b-row>
        </b-modal>

        <b-modal
          :ref="'coachRankIndexMicroModal' + coach.id"
          :title="'Coaches index ranking: ' + title_index_rank"
          ok-only
          ok-title="Close"
        >
          <b-row
            align-v="center"
            v-for="(item, index) in coaches_index_rank"
            :key="index"
            class="p-1"
          >
            <b-col
              cols="1"
              class="text-center"
              :style="coach.id == item.coach.id ? 'color:#FF0000' : ''"
            >
              {{ index + 1 }}
            </b-col>
            <b-col cols="2" class="text-center">
              <b-img
                :src="
                  item.coach && item.coach.allenatore
                    ? item.coach.allenatore.avatar_url
                    : '/assets/images/agente.png'
                "
                height="30"
              ></b-img
            ></b-col>
            <b-col :style="coach.id == item.coach.id ? 'color:#FF0000' : ''">
              <router-link
                :to="{
                  name: 'ScoutingViewCoach',
                  params: { id: item.coach.id },
                }"
                target="_blank"
                :style="
                  'cursor: pointer;' +
                  (coach.id == item.coach.id ? 'color:#FF0000' : '')
                "
                >{{
                  item.coach.allenatore
                    ? item.coach.allenatore.cognome
                    : item.coach.name
                }}
              </router-link>
            </b-col>
            <b-col cols="3">
              <div
                :style="
                  'color:#000000;background:' +
                  bg_opta[item.micro_index_color_rank]
                "
                class="text-center"
              >
                <strong>{{ item.micro_index }}</strong>
              </div></b-col
            >
          </b-row>
        </b-modal>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col>
        <router-link
          :to="{
            name: 'ScoutingVideotagCoach',
            params: { id: coach.id },
          }"
          tag="div"
          style="cursor: pointer"
        >
          <avatar
            :avatar="
              coach.allenatore
                ? coach.allenatore.avatar_url
                : '/assets/images/agente.png'
            "
            :id="coach.id"
          />
        </router-link>
      </b-col>
      <b-col>
        <b-row class="mb-5">
          <b-col cols="12" style="font-size: 0.8rem; font-weight: bold">
            <div
              :title="
                coach.allenatore
                  ? coach.allenatore.nome + ' ' + coach.allenatore.cognome
                  : (coach.initial ? coach.initial : '') + ' ' + coach.name
              "
              style="
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{
                coach.allenatore
                  ? coach.allenatore.nome + " " + coach.allenatore.cognome
                  : (coach.initial ? coach.initial : "") + " " + coach.name
              }}
            </div>
          </b-col>
          <b-col cols="12" style="font-size: 0.8rem; min-height: 18px">{{
            coach.last_stat ? coach.last_stat.team.name : ""
          }}</b-col>
          <b-col cols="12">
            <flag
              :country="
                coach.allenatore && coach.allenatore.paese_nascita
                  ? coach.allenatore.paese_nascita.sigla
                  : ''
              "
              :size="16"
              class="mr-2"
              title="Country of birth"
            ></flag>
            <flag
              :country="
                coach.allenatore && coach.allenatore.passaporto
                  ? coach.allenatore.passaporto.sigla
                  : ''
              "
              :size="16"
              class="mr-2"
              title="Passport"
            ></flag
          ></b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Auth from "@/lib/auth.js";
import moment from "moment";
import Progress from "easy-circular-progress";
import PolarCoach from "@/components/charts/PolarCoach.vue";
import Avatar from "@/components/Avatar.vue";
import Flag from "@/components/Flag.vue";
export default {
  props: {
    coach: {
      type: Object,
      required: true,
    },
    skills: {
      type: Array,
      default: () => [],
    },
    ruolo: {
      type: Object,
      default: null,
    },
  },

  data: function () {
    return {
      fasi: [1, 3, 2, 4],
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
      ],
      principles_title: [
        "PRESSING",
        "RECOVERY",
        "MARKING",
        "BUILD UP",
        "POSSESSION",
        "ENGAGEMENT",
        "UNMARKING",
        "DRIBBLING",
        "WIDE PLAY",
        "FINISHING",
        "CREATIVITY",
        "SET PLAY",
      ],
      principles_order: [9, 8, 10, 12, 1, 6, 3, 7, 2, 5, 4, 11],
      coaches_index_rank: [],
      title_index_rank: null,
    };
  },

  components: {
    PolarCoach,
    Progress,
    Avatar,
    Flag,
  },

  created: function () {},

  computed: {
    auth: function () {
      return Auth;
    },

    user: function () {
      return Auth.user;
    },

    standard_skills: function () {
      return this.skills.filter((item) => item.standard === 1);
    },

    skills_fasi: function () {
      var skills_fasi = [];
      var order = 1;
      for (var x in this.fasi) {
        var fase = this.fasi[x];
        var items = [];
        for (var i in this.skills) {
          var skill = this.skills[i];
          if (skill.phase == fase) {
            var item = {
              id: skill.id,
              order: order,
              weight: 1,
              label: skill.label,
            };
            items.push(item);
            order++;
          }
        }
        var fase = {
          id: "fase" + this.fasi[x],
          order: parseInt(x) + 1,
          weight: items.length,
          items: items,
        };
        skills_fasi.push(fase);
      }
      return skills_fasi;
    },

    /*  coach_data: function () {
      var data = [];
      for (var i in this.skills_fasi) {
        var skill = this.skills_fasi[i];
        for (var x in skill.items) {
          var item = skill.items[x];
          var skill_id = item.id;
          var found = false;
          for (var y in this.coach.stats) {
            var stat = this.coach.stats[y];
            if (stat.skill_id == skill_id) {
              var value = {
                //  id: stat.id,
                id: this.coach.id + "_" + stat.skill_id,
                order: item.order,
                score:
                  Math.round(
                    this.normalizza(skill_id, stat.skill_value) * 100
                  ) / 100,
                value: isNaN(stat.skill_value)
                  ? stat.skill_value
                  : Math.round(stat.skill_value * 100) / 100,
                weight: 1,
                color: this.getBgStat(skill_id, stat.skill_value),
                label: item.label,
              };
              data.push(value);
              found = true;
              break;
            }
          }
          if (!found) {
            var value = {
              id: this.coach.id + "_" + skill_id,
              order: item.order,
              score: 15,
              value: "N/D",
              weight: 1,
              color: "#FFFFFF",
              label: item.label,
            };
            data.push(value);
          }
        }
      }

      return data;
    },  */

    coach_data: function () {
      var data = [];
      if (this.coach.optaIndex) {
        var principles_index = JSON.parse(
          this.coach.optaIndex.principles_index
        );
        var principles_index_color_rank = JSON.parse(
          this.coach.optaIndex.principles_index_color_rank
        );
        for (var i in principles_index) {
          var value = {
            id: this.coach.id + "_" + i,
            order: this.principles_order[i],
            score: principles_index[i],
            value: principles_index[i],
            weight: 1,
            color: this.bg_opta[principles_index_color_rank[i]],
            label: this.principles_title[i],
            sigla: this.principles_title[i].substr(0, 3),
          };
          data.push(value);
        }
      }
      data.sort(function (a, b) {
        return a.order - b.order;
      });
      return data;
    },

    sorted_desc_team_data: function () {
      var desc_data = this.coach_data
        .slice(0)
        .sort(function (a, b) {
          return b.score - a.score;
        })
        .slice(0, 4);
      while (desc_data.length < 4) {
        var value = {
          value: "N/D",
          color: "#FFFFFF",
          label: "?",
        };
        desc_data.push(value);
      }
      return desc_data;
    },
    sorted_asc_team_data: function () {
      var asc_data = this.coach_data
        .slice(0)
        .sort(function (a, b) {
          return a.score - b.score;
        })
        .slice(0, 4);
      while (asc_data.length < 4) {
        var value = {
          value: "N/D",
          color: "#FFFFFF",
          label: "?",
        };
        asc_data.push(value);
      }
      return asc_data;
    },
  },

  methods: {
    getValueStat(skill_id) {
      for (var i in this.coach.stats) {
        var item = this.coach.stats[i];
        if (item.skill_id == skill_id) {
          return (
            "" +
            (this.isFloat(item.skill_value)
              ? Math.round(item.skill_value * 100) / 100
              : item.skill_value)
          );
        }
      }
      return "N/D";
    },

    isFloat(n) {
      return n != "" && !isNaN(n) && Math.round(n) != n;
    },

    getBgStat(skill_id, value) {
      if (value != "N/D") {
        value = parseFloat(value);
        for (var i in this.skills) {
          if (this.skills[i].id == skill_id) {
            for (var x in this.skills[i].range) {
              if (
                this.skills[i].range[x].from == this.skills[i].range[x].to &&
                this.skills[i].reverse
                  ? value >= this.skills[i].range[x].from &&
                    value <= this.skills[i].range[x].to
                  : value <= this.skills[i].range[x].from &&
                    value >= this.skills[i].range[x].to
              ) {
                return this.bg_opta[x];
              }
              if (
                this.skills[i].range[x].from != this.skills[i].range[x].to &&
                ((x < this.skills[i].range.length - 1 && this.skills[i].reverse
                  ? value >= this.skills[i].range[x].from &&
                    value < this.skills[i].range[x].to
                  : value <= this.skills[i].range[x].from &&
                    value > this.skills[i].range[x].to) ||
                  (x == this.skills[i].range.length - 1 &&
                  this.skills[i].reverse
                    ? value >= this.skills[i].range[x].from &&
                      value <= this.skills[i].range[x].to
                    : value <= this.skills[i].range[x].from &&
                      value >= this.skills[i].range[x].to))
              ) {
                return this.bg_opta[x];
              }
              if (
                !this.skills[i].range[x].to &&
                value <= this.skills[i].range[x].from
              ) {
                return this.bg_opta[x];
              }
            }
          }
        }
      }
      return "#FFFFFF";
    },

    normalizza(skill_id, value) {
      var min = 15;
      var max = 100;
      for (var i in this.skills) {
        if (this.skills[i].id == skill_id) {
          if (this.skills[i].max > this.skills[i].min) {
            return (
              min +
              ((value - this.skills[i].min) /
                (this.skills[i].max - this.skills[i].min)) *
                (max - min)
            );
          }
          return 100;
        }
      }
      return value;
    },

    getRankIndexMacro() {
      this.$http
        .get("/coach/rank/index/macro/" + this.coach.optaIndex.id)
        .then((response) => {
          this.coaches_index_rank = response.data;
          this.title_index_rank = "Macro";
          this.$refs["coachRankIndexMacroModal" + this.coach.id].show();
        });
    },

    getRankIndexMicro() {
      this.$http
        .get("/coach/rank/index/micro/" + this.coach.optaIndex.id)
        .then((response) => {
          this.coaches_index_rank = response.data;
          this.title_index_rank = "Micro";
          this.$refs["coachRankIndexMicroModal" + this.coach.id].show();
        });
    },
  },

  filters: {
    year: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("YYYY");
      }
      return "";
    },
    age: function (date) {
      var birthday = moment(date);
      if (birthday.isValid()) {
        return moment().diff(birthday, "years");
      }
      return "";
    },
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
